export const columns_config = () => {
	return [{
			name: "柜号\跟踪号\货物单号",
			nameEn:"container NO.\Trucking No.\Goods No.",
			data: 'packingNo',
			// editor: 'select',
			// selectOptions: []
			type: 'text',
			// type: 'dropdown',
			// source: []
			// width: 200,
		},
		{
			name: "到仓方式",
			nameEn:"packing type",
			data: 'packTypeName',
			// type: 'text',
			type: 'dropdown',
			source: ['20FT','40FT','45FT','Package','Pallet']
		},
		{
			name: "Fba 地址编号 FBA Address",
			nameEn:"如果是私人地址，请按格式填写:[address,city,state,zipCode]）",
			data: 'address',
			type: 'text',
			// type: 'dropdown',
			// source: []
		},
		{
			name: "转运方式",
			nameEn:"Transfer Way",
			data: 'trWayName',
			// type: 'text',
			type: 'dropdown',
			source: ['卡派','自提','快递']
		},
		{
			name: "是否换标",
			nameEn:"Change Label",
			data: 'isChangeLabelName',
			// type: 'text',
			type: 'dropdown',
			source: ['YES','NO']
		},
		{
			name: "预计到仓日期",
			nameEn:"arrive Date（格式：yyyy-MM-dd）",
			data: 'arriveDate',
			// type: 'text',
			type: 'date',
			dateFormat: 'YYYY-MM-DD'
		},

		{
			name: "外箱标识/箱标",
			nameEn:"Mark",
			data: 'ctnMark',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "箱数",
			nameEn:"Total Box",
			data: 'quantity',
			type: 'numeric',
			// type: 'text',
			// type: 'dropdown',
			// source: []
			// type: 'date', 
			// dateFormat: 'YYYY-MM-DD'
		},
		
		{
			name: "托盘数",
			nameEn:"pallet count",
			data: 'pallteCount',
			// type: 'numeric',
			// type: 'text',
			type: 'numeric',
		},

		{
			name: "总体积m³",
			nameEn:"Total Vol",
			data: 'totalVol',
			// type: 'numeric',
			// type: 'text',
			type: 'numeric',
		},
		{
			name: "总重量(LB)",
			nameEn:"Total Weight",
			data: 'totalWeight',
			// type: 'text',
			type: 'numeric',
		},
		{
			name: "FBA",
			nameEn:"(预约派送是Amazon仓库时:必填)",
			data: 'fbaNo',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "PO",
			nameEn:"(预约派送是Amazon仓库时:必填)",
			data: 'fbaPre',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "备注",
			nameEn:"Remark",
			data: 'remark',
			// type: 'numeric',
			type: 'text',
		},
		// {
		// 	name: "ID",
		// 	data: 'id',
		// 	type: 'text',
		// 	readOnly: true
		// },
	];
}

export const nestedHeadersTop_config = () => {
	return [];
}