<template>
	<div style="">
		<div ref="hotTable"></div>
	</div>
</template>

<script>
	import Handsontable from 'handsontable';
	import "handsontable/dist/handsontable.full.css";
	import 'handsontable/languages/zh-CN';

	import {
		clearObjectVal
	} from '@/utils/common.js';
	
	import {
		calcHeight,
		settings
	} from '@/utils/hotTableSettings.js';
	
	import {
		columns_config,
		nestedHeadersTop_config
	} from './TransportFastHotTable.js';


	let columns = columns_config();
	
	// let DataRowObj = {};
	// columns.map(item => {
	// 	DataRowObj[item.data] = null;
	// });
	
	// let dataObject = [DataRowObj];
	// let colHeaders = columns.map(item => {
	// 	return item.name;
	// });
	// let colHeadersEn = columns.map(item => {
	// 	return item.nameEn;
	// });
	// let colWidths = columns.map(item => {
	// 	if(item.width){
	// 		return item.width;
	// 	} else {
	// 		return 100;
	// 	}
	// });
	let option = {
		height: calcHeight(490,510),
	}
	
	let nestedHeadersTop = [];
	// let nestedHeadersTop = [
	// 	[
	// 		{ label: this.$t('i18nn_d7aef993e73ebf60'), colspan: 6 }, 
	// 		{ label: 'FBA', colspan: 2 }, 
	// 		{ label: this.$t('i18nn_e6e818680d77e014'), colspan: 2 }, 
	// 		// { label: this.$t('i18nn_4851293ecdd539ed'), colspan: 7 },
	// 		'',
	// 	]
	// ];
	let hiddenColumns = {
			// columns: [10],
			// indicators: true,
			// exclude hidden columns from copying and pasting
			copyPasteEnabled: false
		};
	let settingData = settings(columns,option,nestedHeadersTop,hiddenColumns);
	let hotTableSettings = settingData.config;
	let DataRowObj = settingData.DataRowObj;
	// let hotTableSettings = {
	// 	data: dataObject,
	// 	className: 'htLeft',
	// 	licenseKey: 'non-commercial-and-evaluation',
	// 	// stretchH: 'all',
	// 	//   width: 805,
	// 	// autoWrapRow: true,
	// 	// height: 487,
	// 	// maxRows: 60,
	// 	manualRowResize: true,
	// 	manualColumnResize: true,
	// 	// rowHeaders: true,
	// 	manualRowMove: true,
	// 	manualColumnMove: true,
	// 	contextMenu: true,
	// 	// filters: true,
	// 	// dropdownMenu: true,
	// 	copyable: true,
	// 	columns: columns,
	// 	// hiddenColumns: true,
	// 	hiddenColumns: {
	// 		columns: [15],
	// 		// indicators: true,
	// 		// exclude hidden columns from copying and pasting
	// 		copyPasteEnabled: false
	// 	},
	// 	stretchH: 'all',
	// 	width: '100%',
	// 	autoWrapRow: true,
	// 	// minHeight: 100,
	// 	height: 400,
	// 	// height: 'auto',
	// 	// height: '100%',
	// 	colWidths: colWidths,
	// 	rowHeights: 25,
	// 	// rowHeaders: true,
	// 	// colHeaders: true,
	// 	minRows: 1,
	// 	// maxRows: 22,
	// 	rowHeaders: true,
	// 	nestedHeaders:[
	// 		[{ label: this.$t('i18nn_b74bad1a7a5403b1'), colspan: 7 }, { label: '外箱规格(当一箱一件时，外箱规格可不填)', colspan: 7 },''],
	// 		colHeadersEn,
	// 		colHeaders,
			
	// 	],
	// 	// colHeaders: colHeaders,
	// 	contextMenu: {
	// 		// 单元格右击展示菜单
	// 		items: {
	// 			// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
	// 			// hsep6: '---------',
	// 			// upload_img: {
	// 			//   name: this.$t('i18nn_c5329b923a1a6213'),
	// 			//   // callback: (key, selection, clickEvent) => {
	// 			//   callback: function(key, selection, clickEvent) {
	// 			//     console.log(arguments);
	// 			//     _vue_this.openUpLoad();
	// 			//     _vue_this.selCell = selection[0];
	// 			//     console.log(_vue_this.selCell);
	// 			//   }
	// 			// },
	// 			// hsep6: '---------',
	// 			row_below: {
	// 				name: this.$t('i18nn_382c5bafd600d2bd')
	// 			},
	// 			row_above: {
	// 				name: this.$t('i18nn_8b456c5396713d9a')
	// 			},
				
	// 			// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
	// 			// hsep1: '---------',
	// 			// col_left: {
	// 			// 	name: this.$t('i18nn_77054b0e5ea4d30d')
	// 			// },
	// 			// col_right: {
	// 			// 	name: this.$t('i18nn_1b2106ded89310ba')
	// 			// },
	// 			// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
	// 			hsep2: '---------',
	// 			remove_row: {
	// 				name: this.$t('i18nn_f04cf5507ecc5774')
	// 			},
	// 			// remove_col: {
	// 			// 	name: this.$t('i18nn_327aa3849b3d5c68')
	// 			// },
	// 			// "undo", "redo"
	// 			hsep7: '---------',
	// 			separator: Handsontable.plugins.ContextMenu.SEPARATOR,
	// 			undo: {
	// 				name: this.$t('i18nn_b4e5993f97fe04ee')
	// 			},
	// 			redo: {
	// 				name: this.$t('i18nn_1a42987a0df962e9')
	// 			},
	// 			// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
	// 			hsep3: '---------',
	// 			make_read_only: {
	// 				name: this.$t('i18nn_f7f39a6e6d9d26cb')
	// 			},
	// 			// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
	// 			alignment: {
	// 				name: this.$t('i18nn_0607472de4525358')
	// 			},
	// 			// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
	// 			// 'borders':{
	// 			//   name: this.$t('i18nn_ea37e4e88aada736')
	// 			// },
	// 			hsep4: '---------',
	// 			copy: {
	// 				name: this.$t('i18nn_29f67f0644a78bf3')
	// 			},
	// 			cut: {
	// 				name: this.$t('i18nn_6b7fbcd658f0ccdb')
	// 			},
	// 			// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
	// 			hsep5: '---------',
	// 			clear_custom: {
	// 				name: this.$t('i18nn_c420ac9915ca288d'),
	// 				callback: function() {
	// 					this.clear();
	// 				}
	// 			},
	// 		}
	// 	},
	// 	language: 'zh-CN'
	// };

	export default {
		components: {

		},
		props: {
			// selectDicOption:{}
		},
		watch: {

		},
		data() {
			return {
				hotTable: {},
				// plugin: null,
				selectOption: {
					wh_car_tms_type: [],
					// packing_type: [{
					// 	code:"1",
					// 	codeText:"package"
					// },{
					// 	code:"2",
					// 	codeText:"pallet"
					// },{
					// 	code:"3",
					// 	codeText:"container"
					// }],
					// container_size: [{
					// 	code:"1",
					// 	codeText:"20FT"
					// },{
					// 	code:"2",
					// 	codeText:"40FT"
					// },{
					// 	code:"3",
					// 	codeText:"45FT"
					// }]
				},
			};
		},
		created() {
			console.log('created');
			this.$nextTick(() => {
				this.initHotTable();
			});
		},
		mounted() {
			// this.$nextTick(() => {
				// this.getDicData();
			// });
		},
		methods: {
			//初始化HotTable
			initHotTable() {
				// this.$nextTick(() => {
				// let hotElement = document.querySelector('#hotTable');
				let hotElement = this.$refs.hotTable;
				console.log('hotElement', hotElement);

				let hot = new Handsontable(hotElement, hotTableSettings);
				this.hotTable = hot;
				// this.plugin = this.hotTable.getPlugin('hiddenColumns');
				console.log('this.hotTable', this.hotTable);
				this.clear();
			},
			//更新列表配置
			updateSettingsColumns() {
				// let select_0 = this.selectOption.wh_transfer_Inwh_type.map(item => {
				// 	return item.codeText;
				// });
				// let select_1 = this.selectOption.wh_size_unit.map(item => {
				// 	return item.codeText;
				// });
				// hotTableSettings.columns[0].selectOptions = select_0;
				// hotTableSettings.columns[8].selectOptions = select_1;
				
				// hotTableSettings.columns[5].source = this.selectOption.wh_car_tms_type.map(item => {
				// 	return item.codeText;
				// });
				// hotTableSettings.columns[1].source = this.selectOption.wh_packing_type.map(item => {
				// 	return item.codeText;
				// });
				// hotTableSettings.columns[2].source = this.selectOption.wh_container_size.map(item => {
				// 	return item.codeText;
				// });
				
				this.hotTable.updateSettings({
					columns: hotTableSettings.columns
				});
			},
			//表格SKU列关联隐藏
			// hideSkuColumn() {
			// 	console.log('hideSkuColumn');
			// 	this.hotTable.updateSettings({
			// 		hiddenColumns: {
			// 			columns: [9, 10, 11, 13],
			// 			// indicators: true,
			// 			// exclude hidden columns from copying and pasting
			// 			copyPasteEnabled: false
			// 		}
			// 	});
			// 	// this.plugin.hideColumn(9, 10, 11, 13);
			// },
			// //表格SKU列关联显示
			// showSkuColumn() {
			// 	console.log('showSkuColumn');
			// 	this.hotTable.updateSettings({
			// 		hiddenColumns: {
			// 			columns: [13],
			// 			// indicators: true,
			// 			// exclude hidden columns from copying and pasting
			// 			copyPasteEnabled: false
			// 		}
			// 	});
			// 	// this.plugin.showColumn(13);
			// },

			//数据字典格式化
			// formatterObjData(objArr) {
			// 	let Arr = [];
			// 	// console.log('this.selectOption', this.selectOption);
			// 	objArr.map(item => {
			// 		item.inWhType = item.inWhTypeName;
			// 		item.ctnUnit = item.ctnUnitName;
			// 		Arr.push(item);
			// 	});
			// 	return Arr;
			// 	// return objArr;
			// },
			//初始化和加载数据
			loadData(objArr) {
				// let data = this.formatterObjData(objArr);
				console.log('loadData', objArr);
				if(objArr.length<=0){
					this.clear();
				} else {
					this.hotTable.loadData(objArr);
				}
				
			},
			//数组转对象
			formatterArrToObj(arr) {
				let reArr = [];
				// if(!this.selectOption.wh_transfer_Inwh_type || this.selectOption.wh_transfer_Inwh_type.length<=0){
				// 	this.selectOption = this.selectDicOption;
				// }
				arr.map((item,index) => {
					// let inWhType = item[0] ? item[0] : '';
					// let inWhTypeObj = this.selectOption.wh_transfer_Inwh_type.find(item2 => {
					// 	return item2.codeText == inWhType.replace(/(^\s*)|(\s*$)/g, '');
					// });
					// let TypeCodeText = item[5] ? item[5] : '';
					// let wh_car_tms_type_o = this.selectOption.wh_car_tms_type.find(item2 => {
					// 	return item2.codeText == TypeCodeText.replace(/(^\s*)|(\s*$)/g, '');
					// });
					let obj = {}
					columns.map((item2,index2)=>{
						obj[item2.data] = item[index2];
					});
					// if(wh_car_tms_type_o){
					// 	obj.carTmsType = wh_car_tms_type_o.code;
					// }
					
					reArr.push(obj);
					
					// reArr.push({
					// 	columns[0].data: item[0],
					// 	"ctnMark": item[1], //this.$t('i18nn_8a43886ff356ed78'),
					// 	"ctnCount": item[2], //this.$t('i18nn_53fa7ff1ea383b2f'),
					// 	"inWhPallte": item[3], //this.$t('i18nn_18c1c1a3ea76e9d3'),

					// 	"ctnL": item[4], //this.$t('i18nn_70b5c883b227851b'),
					// 	"ctnW": item[5], //this.$t('i18nn_0a389c3a5e3938d3'),
					// 	"ctnH": item[6], //this.$t('i18nn_5c31dfdb8f4781ec'),
					// 	"ctnWeight": item[7], //this.$t('i18nn_91263f8fa1a94da9'),
					// 	"ctnUnit": ctnUnitObj && ctnUnitObj.code ? ctnUnitObj.code : ctnUnit, //this.$t('i18nn_b6bf0a07fe26f74f')
					// 	"goodsSku": item[9], //"箱内SKU",
					// 	"ctnSkuCount": item[10], //this.$t('i18nn_85a10d3e5ea0e103'),
					// 	"skuTotal": item[11], //this.$t('i18nn_bad53577db0da2d3'),
					// 	"remark":item[12],//备注
					// 	id: item[13] ? item[13] : null
					// });
				});
				return reArr;
			},
			//获取数据
			getData() {
				let hotTableData = this.hotTable.getData();
				console.log(hotTableData);
				let data = this.formatterArrToObj(hotTableData);
				console.log(data);
				return data;
			},
			//清空数据
			clear() {
				this.$nextTick(() => {
					console.log('clear', DataRowObj);
					// this.loadData([DataRowObj])
					let new_DataRowObj = clearObjectVal(DataRowObj);
					this.hotTable.loadData([new_DataRowObj]);
				});
				// this.hotTable.table.clear();

			},
			//查询数据字典
			// getDicData() {
			// 	//如果有传值
			// 	// if(this.selectDicOption.wh_transfer_Inwh_type && this.selectDicOption.wh_transfer_Inwh_type.length<=0){
			// 	// 	this.selectOption = this.selectDicOption;
			// 	// 	this.updateSettingsColumns();
			// 	// 	return;
			// 	// } else {
			// 	// 	console.log("selectDicOption无值");
			// 	// }
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_car_tms_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.queryErrorFailed');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 			this.updateSettingsColumns();
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.queryRequestFailed'));
			// 			// this.initHotTable();
			// 		});
			// }
		}
	}
</script>

<style>
	/* .htSelectEditor {
		display: inline-block !important;
	} */
	.handsontable .htAutocompleteArrow {
		color:#999;
	}
</style>
